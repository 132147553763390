import React, { Component } from 'react';
import { Route, Routes } from "react-router-dom";
import CreateArticle from './pages/createarticle';
import DeleteArticle from './pages/deletearticle';
import Home from './pages/home';

//pages
import Login from './pages/login';
import Newsletter from './pages/newsletter';
import Userprofile from './pages/userprofile';

class App extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/post/create" element={<CreateArticle />} />
        <Route path="/user/profile" element={<Userprofile />} />
        <Route path="/post/delete" element={<DeleteArticle />} />
        <Route path="/send/newsletter" element={<Newsletter />} />
      </Routes>
    )
  }
}

export default App;
