import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

//endpoints
import Endpoints from '../constants/endpoints';

import withNavigateHook from '../component/withNavigateHook';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: true,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.username.trim() === '' || this.state.password.trim() === '') {
            Toast.fire({
                icon: 'error',
                title: 'Invalid Login Credentials'
            });
            return;
        }
        //convert state to request body
        const reqBody = JSON.stringify(this.state);
        fetch(Endpoints.auth_user, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: reqBody
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.statusCode === 0) {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('userData', JSON.stringify(data));
                    this.fetchUserDetails(data.token);
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: data.message + ". " + data.error
                    });
                }
            }).catch(err => {
                console.log(err);
            });
    }

    fetchUserDetails(token) {
        fetch(Endpoints.get_user_details, {
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + token,
                Accept: "application/json"
            }
        })
            .then((res) => res.json())
            .then((userDetails) => {
                localStorage.removeItem('userData');
                localStorage.setItem('userData', JSON.stringify(userDetails));
                this.props.navigation('/home');
            }).catch(err => {
                console.log(`error occured while fetching user details. -> ${err}`);
                Toast.fire({
                    icon: "error",
                    title: err.message
                });
            });
    }

    componentDidMount() {
        // add class to body element
        document.body.classList.add("hold-transistion");
        document.body.classList.add("login-page");
    }

    componentWillUnmount() {
        //remove styles when component unmounts
        document.body.classList.remove("hold-transistion");
        document.body.classList.remove("login-page");
    }

    render() {
        const { error } = this.state;
        return (
            <div className="login-box">
                <div className="login-logo">
                    <a className="mb-2">
                        <img src="/images/tmsLogoReverseNoBg.png" className="img-fluid" style={{ height: '90px' }} />
                    </a>
                    <h4 className='mt-2'>
                        <p>Admin Portal</p>
                    </h4>
                </div>
                <div className="card login-form-effects">
                    <div className="card-body login-card-body radius-effects">
                        <p className="login-box-msg">Sign in</p>
                        <form>
                            <div className="input-group mb-3">
                                <input type="email"
                                    className="form-control"
                                    placeholder="username"
                                    required
                                    value={this.state.username}
                                    onChange={e => { this.setState({ username: e.target.value }) }}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password"
                                    className="form-control"
                                    placeholder="password"
                                    required
                                    value={this.state.password}
                                    onChange={e => { this.setState({ password: e.target.value }) }}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="offset-4 col-4 text-center">
                                    <button type="submit"
                                        className="btn btn-sm bg-gradient-primary btn-block"
                                        onClick={e => { this.handleSubmit(e) }}
                                    >
                                        Sign In
                                    </button>
                                </div>
                            </div>
                        </form>
                        <p className="mb-3 text-center">
                            <Link to={"/change/password"}>Forgot password ?</Link>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withNavigateHook(Login);