import React, { Component } from "react";

class SideMenuListWrapper extends Component {
    render() {
        return (
            <li className={this.props.listClass}>
                {this.props.children}
            </li>
        );
    }
}

export default SideMenuListWrapper;