import Endpoints from "./endpoints";

const imageDomain = "https://themiddlestump.xyz/images/blog/";

class UploadAdapter {

    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return new Promise((resolve, reject) => {
            this.loader.file.then(
                (file) => {
                    const body = new FormData();
                    body.append("image", file);
                    fetch(Endpoints.article_image_upload, {
                        method: "post",
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        },
                        body: body
                    })
                        .then((res) => res.json())
                        .then((res) => {
                            resolve({
                                default: `${imageDomain + res.originalname}`
                            });
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
        });
    }
}

export default UploadAdapter