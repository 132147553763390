import React, { Component } from "react";
import { Link } from "react-router-dom";
import withNavigateHook from "../withNavigateHook";
import DropDownItem from "./chunk/dropdownItem";

class UserProfileNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notification: [],
            notificationCount: 0,
            userDetails: JSON.parse(localStorage.getItem('userData'))
        }
    }

    handleLogout(e) {
        e.preventDefault();
        localStorage.setItem('token', '');
        this.props.navigation('/');
        window.location.reload(false);
    }

    render() {
        const { notification, notificationCount, userDetails } = this.state;
        return (
            <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
                <div className="container">
                    <Link to={"/home"} className="navbar-brand">
                        <span className="brand-text font-weight-light">TheMiddleStump - Admin Portal</span>
                    </Link>
                    <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse order-3" id="navbarCollapse">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" data-widget="pushmenu" href="#" role="button">
                                    <i className="fas fa-bars"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <Link to={"/home"} className="nav-link">Home</Link>
                            </li>
                        </ul>
                    </div>
                    <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#">
                                <i className="fas fa-comments"></i>
                                <span className="badge badge-danger navbar-badge">3</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <DropDownItem imageUrl="/images/avatar_128x128.jpg"
                                    name="Brad Diesel"
                                    msgText="Call me whenever you can..."
                                    timeText="4 Hours Ago"
                                />
                                <div className="dropdown-divider"></div>
                                <DropDownItem imageUrl="/images/avatar_128x128.jpg"
                                    name="John Pierce"
                                    msgText="I got your message bro"
                                    timeText="4 Hours Ago"
                                />
                                <div className="dropdown-divider"></div>
                                <DropDownItem imageUrl="/images/avatar_128x128.jpg"
                                    name="Nora Silvester"
                                    msgText="The subject goes here"
                                    timeText="4 Hours Ago"
                                />
                                <div className="dropdown-divider"></div>
                                <a href="#" className="dropdown-item dropdown-footer">See All Messages</a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#">
                                <i className="far fa-bell"></i>
                                <span className="badge badge-warning navbar-badge">{notificationCount === 0 ? '' : notificationCount}</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <span className="dropdown-item dropdown-header">
                                    {notificationCount + notificationCount <= 1 ? ' Notification' : ' Notifications'}
                                </span>
                                <div className="dropdown-divider"></div>
                                {notificationCount === 0
                                    ? <div>
                                        <a href="#" className="dropdown-item">
                                            <i className="far fa-bell mr-2"></i>
                                            <span>You have no notifications</span>
                                            <span className="float-right text-muted text-sm"></span>
                                        </a>
                                    </div>
                                    : notification.map((n, key) => {
                                        <div key={key}>
                                            <a href={"/" + n.actionLink} className="dropdown-item">
                                                <i className="far fa-bell mr-2"></i>
                                                <span>
                                                    {n.notification}
                                                </span>
                                                <span className="float-none text-muted text-sm justify-content-center"></span>
                                            </a>
                                            <div className="dropdown-divider"></div>
                                        </div>
                                    })
                                }
                                {notificationCount > 3
                                    ? <a href="/user/notification"
                                        className="dropdown-item dropdown-footer">
                                        See All Notifications
                                    </a>
                                    : ''
                                }
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="dropdown" href="#">
                                <i className="fas fa-user"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <span className="dropdown-item dropdown-header">
                                    {userDetails.user.last_name + ' ' + userDetails.user.first_name}
                                </span>
                                <div className="dropdown-divider"></div>
                                <Link to={"/user/profile"} className="dropdown-item">
                                    <i className="fas fa-user mr-2"></i> Profile
                                    <span className="float-right text-muted text-sm"></span>
                                </Link>
                                <div className="dropdown-divider"></div>
                                <a href="#" className="dropdown-item">
                                    <i className="fas fa-lock mr-2"></i>Password Change Date
                                </a>
                                <div className="dropdown-divider"></div>
                                <form id="logoutForm">
                                    <button type="submit"
                                        className="dropdown-item dropdown-footer btn btn-sm btn-primary btn-block"
                                        onClick={e => { this.handleLogout(e) }}
                                    >
                                        <i className="fas fa-sign-out mr-2"></i>Sign Out
                                    </button>
                                </form>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
}

export default withNavigateHook(UserProfileNav);