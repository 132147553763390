import React, { Component } from "react";

class ContentWrapper extends Component {
    render() {
        return (
            <div className="content-wrapper">
                {this.props.children}
            </div>
        );
    }
}

export default ContentWrapper;