import React from "react";

export default function DropDownItem(props) {
    return (
        <a href="#" className="dropdown-item">
            <div className="media">
                <img src={props.imageUrl}
                    alt="User Avatar"
                    className="img-size-50 mr-3 img-circle"
                />
                <div className="media-body">
                    <h3 className="dropdown-item-title">
                        {props.name}
                        <span className="float-right text-sm text-danger">
                            <i className="fas fa-star"></i>
                        </span>
                    </h3>
                    <p className="text-sm">{props.msgText}</p>
                    <p className="text-sm text-muted">
                        <i className="far fa-clock mr-1"></i>
                        {props.timeText}
                    </p>
                </div>
            </div>
        </a>
    );
}