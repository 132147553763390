const Endpoints = require("./endpoints");

exports.VALIDATE_TOKEN = (userToken, callback) => {
    fetch(Endpoints.verify_token, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ token: userToken })
    })
        .then((res) => res.json())
        .then((data) => {
            callback(data);
        }).catch(err => {
            console.log(`error validating token -> ${err}`);
            callback({ statusCode: 90, message: err });
        });
}

exports.AUTH_USER = (callback) => {

    const userData = JSON.parse(localStorage.getItem('userData'));
    const authStatus = localStorage.getItem('isAuth', true);
    console.log(`dashboard auth status -> ${JSON.stringify(authStatus)}`);
    if (authStatus === null || authStatus === undefined || !authStatus) {
        callback({ statusCode: 90, message: 'err' });
    } else {
        if (userData) {
            this.VALIDATE_TOKEN(valid => {
                if (valid.statusCode !== 0) {
                    localStorage.setItem('isAuth', false);
                    this.props.navigation('/login');
                    window.location.reload(false);
                } else {
                    fetch(Endpoints.dashboard_data)
                        .then((res) => res.json())
                        .then((summaryData) => {
                            this.setState({ data: summaryData, dataLoaded: true, loadPage: true });
                        }).catch(err => {
                            console.log(`summary data error -> ${err}`);
                        });
                }
            });
        } else {
            localStorage.setItem('isAuth', false);
            this.props.navigation('/login');
            window.location.reload(false);
        }
    }
}