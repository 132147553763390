const liveServer = "https://server.themiddlestump.xyz";
const baseUrl = process.env.NODE_ENV === 'production' ? `${liveServer}/api/v2` : "/api/v2";
const Endpoints = {
    auth_user: baseUrl + '/auth/login',
    verify_token: baseUrl + '/verify-token',
    dashboard_data: baseUrl + '/dash/summary-data',
    pos_tags: process.env.NODE_ENV === 'production' ? `${liveServer}/api/client/tags` : '/api/client/tags',
    create_article: baseUrl + '/create/article',
    upload_profile_photo: baseUrl + '/upload/profile-photo',
    get_user_details: baseUrl + '/user-details',
    change_password: baseUrl + '/change/password',
    change_email: baseUrl + '/change/email',
    get_all_articles: baseUrl + '/all/article',
    article_image_upload: baseUrl + '/article/image/upload',
    create_newsletter_queue: baseUrl + '/newsletter/content'
}

module.exports = Endpoints;