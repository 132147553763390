import React, { Component } from "react";
import { Link } from "react-router-dom";
//components
import ContentWrapper from "../component/common/chunk/contentWrapper";
import HeaderBreadcrumb from "../component/common/chunk/headerbreadcrumb";
import Wrapper from "../component/common/chunk/wrapper";
import Footer from "../component/common/footer";
import Nav from "../component/common/nav";
import SideMenu from "../component/common/sidemenu";
import withNavigateHook from "../component/withNavigateHook";
import Endpoints from "../constants/endpoints";
import { VALIDATE_TOKEN } from "../constants/validation";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: '',
            error: null,
            data: {},
            dataLoaded: false,
            loadPage: false
        }
    }

    authUser() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = localStorage.getItem('token');
        if (token === null || token === undefined || token.trim() === '') {
            this.props.navigation('/');
            window.location.reload(false);
        } else {
            if (userData) {
                VALIDATE_TOKEN(token, (valid) => {
                    if (valid.statusCode !== 0) {
                        console.log(`token is not valid after verification`);
                        localStorage.setItem('token', '');
                        this.props.navigation('/');
                        window.location.reload(false);
                    } else {
                        console.log(`token is valid after verification`);
                        fetch(Endpoints.dashboard_data, {
                            method: "GET",
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token'),
                                Accept: "application/json"
                            }
                        })
                            .then((res) => res.json())
                            .then((summaryData) => {
                                this.setState({ data: summaryData, dataLoaded: true, loadPage: true });
                            }).catch(err => {
                                console.log(`summary data error -> ${err}`);
                            });
                    }
                });
            } else {
                localStorage.setItem('token', '');
                this.props.navigation('/');
                window.location.reload(false);
            }
        }
    }

    componentDidMount() {
        //validate token
        this.authUser();

        // add class to body element
        document.body.classList.add("hold-transistion");
        document.body.classList.add("sidebar-mini");
        document.body.classList.add("layout-fixed");
        document.body.classList.add("layout-navbar-fixed");
        document.body.classList.add("layout-footer-fixed");
    }

    componentWillUnmount() {
        //remove styles when component unmounts
        document.body.classList.remove("hold-transistion");
        document.body.classList.remove("sidebar-mini");
        document.body.classList.remove("layout-fixed");
        document.body.classList.remove("layout-navbar-fixed");
        document.body.classList.remove("layout-footer-fixed");
    }

    render() {
        const { dataLoaded, data, loadPage } = this.state;
        return (
            <Wrapper>
                <Nav />
                <SideMenu />
                <ContentWrapper>
                    <div className="content-header">
                        <HeaderBreadcrumb headerText="Admin Dashboard" linkText="Home" currentPage="Dashboard" />
                        <hr />
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-12 col-sm-12">
                                    <div className="card card-primary card-outline card-outline-tabs">
                                        <div className="card-header p-0 border-bottom-0">
                                            <div className="card-tools">
                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                    <i className="fas fa-minus"></i>
                                                </button>
                                            </div>
                                            <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active"
                                                        id="daily-tab" data-toggle="pill"
                                                        href="#daily" role="tab" aria-controls="daily"
                                                        aria-selected="true">
                                                        Daily Overview
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="weekly-tab"
                                                        data-toggle="pill" href="#weekly"
                                                        role="tab" aria-controls="weekly"
                                                        aria-selected="false">
                                                        Last 7 Days
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="monthly-tab"
                                                        data-toggle="pill" href="#monthly"
                                                        role="tab" aria-controls="monthly"
                                                        aria-selected="false">
                                                        This Month
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="card-body"> {!dataLoaded ?
                                            <div className="tab-content" id="custom-tabs-four-tabContent"></div> :
                                            <div className="tab-content" id="custom-tabs-four-tabContent">
                                                <div className="tab-pane fade show active" id="daily" role="tabpanel" aria-labelledby="daily-tab">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-6 col-md-3">
                                                            <div className="info-box">
                                                                <span className="info-box-icon bg-info elevation-1">
                                                                    <i className="fas fa-calendar-day"></i>
                                                                </span>
                                                                <div className="info-box-content">
                                                                    <span className="info-box-text">Date</span>
                                                                    <span className="info-box-number">{data.date}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-3">
                                                            <div className="info-box mb-3">
                                                                <span className="info-box-icon bg-success elevation-1">
                                                                    <i className="fas fa-user-clock"></i>
                                                                </span>
                                                                <div className="info-box-content">
                                                                    <a href="#">
                                                                        <span className="info-box-text">New Subscribers</span>
                                                                    </a>
                                                                    <span className="info-box-number">{data.subscribers.daily}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix hidden-md-up"></div>

                                                        <div className="col-12 col-sm-6 col-md-3">
                                                            <div className="info-box mb-3">
                                                                <span className="info-box-icon bg-danger elevation-1">
                                                                    <i className="fas fa-comments"></i>
                                                                </span>
                                                                <div className="info-box-content">
                                                                    <a href="#">
                                                                        <span className="info-box-text">Comments </span>
                                                                    </a>
                                                                    <span className="info-box-number" text="">{data.comments.daily}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="weekly" role="tabpanel" aria-labelledby="weekly-tab">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-6 col-md-3">
                                                            <div className="info-box">
                                                                <span className="info-box-icon bg-info elevation-1">
                                                                    <i className="fas fa-user-friends"></i>
                                                                </span>
                                                                <div className="info-box-content">
                                                                    <a href="#">
                                                                        <span className="info-box-text">New Subscribers</span>
                                                                    </a>
                                                                    <span className="info-box-number">{data.subscribers.weekly}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-3">
                                                            <div className="info-box mb-3">
                                                                <span className="info-box-icon bg-success elevation-1">
                                                                    <i className="fas fa-comments"></i>
                                                                </span>
                                                                <div className="info-box-content">
                                                                    <a href="#">
                                                                        <span className="info-box-text">Comments</span>
                                                                    </a>
                                                                    <span className="info-box-number">{data.comments.weekly}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix hidden-md-up"></div>
                                                        <div className="col-12 col-sm-6 col-md-3">
                                                            <div className="info-box mb-3">
                                                                <span className="info-box-icon bg-danger elevation-1">
                                                                    <i className="fas fa-inbox"></i>
                                                                </span>
                                                                <div className="info-box-content">
                                                                    <a href="#">
                                                                        <span className="info-box-text">Feedback Messages</span>
                                                                    </a>
                                                                    <span className="info-box-number">{data.feedbacks.weekly}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="monthly" role="tabpanel"
                                                    aria-labelledby="monthly-tab">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-6 col-md-3">
                                                            <div className="info-box">
                                                                <span className="info-box-icon bg-info elevation-1">
                                                                    <i className="fas fa-user-friends"></i>
                                                                </span>
                                                                <div className="info-box-content">
                                                                    <a href="#">
                                                                        <span className="info-box-text">New Subscribers</span>
                                                                    </a>
                                                                    <span className="info-box-number">{data.subscribers.monthly}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-3">
                                                            <div className="info-box mb-3">
                                                                <span className="info-box-icon bg-success elevation-1">
                                                                    <i className="fas fa-comments"></i>
                                                                </span>
                                                                <div className="info-box-content">
                                                                    <a href="#">
                                                                        <span className="info-box-text">Comments</span>
                                                                    </a>
                                                                    <span className="info-box-number">{data.comments.monthly}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix hidden-md-up"></div>
                                                        <div className="col-12 col-sm-6 col-md-3">
                                                            <div className="info-box mb-3">
                                                                <span className="info-box-icon bg-danger elevation-1">
                                                                    <i className="fas fa-inbox"></i>
                                                                </span>
                                                                <div className="info-box-content">
                                                                    <a href="#">
                                                                        <span className="info-box-text">Feedback Messages</span>
                                                                    </a>
                                                                    <span className="info-box-number">{data.feedbacks.monthly}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="offset-md-3 col-12 col-sm-6 col-md-3">
                                        <div className="info-box">
                                            <span className="info-box-icon bg-info elevation-1">
                                                <i className="fas fa-newspaper"></i>
                                            </span>
                                            <div className="info-box-content">
                                                <Link to="/post/create">
                                                    <span className="info-box-text">Create Article</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <div className="info-box">
                                            <span className="info-box-icon bg-warning elevation-1">
                                                <i className="fas fa-edit"></i>
                                            </span>
                                            <div className="info-box-content">
                                                <Link to="/send/newsletter">
                                                    <span className="info-box-text">Send Newsletter</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </ContentWrapper >
                <Footer />
                <aside className="control-sidebar control-sidebar-dark"></aside>
            </Wrapper >
        )
    }
}

export default withNavigateHook(Home);